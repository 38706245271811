import React from "react"
import { graphql, /*Link*/ } from "gatsby"
import styled from 'styled-components'
import AspectRatio from 'react-aspect-ratio'
import Image from 'gatsby-image';
import Scrollspy from 'react-scrollspy'
import Layout from "../components/layout"
import slugify from 'slugify';
import SEO from "../components/seo"
import Hero from "../components/hero"
import Steps from '../components/steps'

const NavItem = styled.div`
  opacity:.5;

  &.is-current {
    opacity:1;
  }

  a:hover {
    text-decoration: underline;
  }
`

const Logo = styled.div`
  height:19px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  > img {
    display:block;
    height:100%;
    object-fit:contain;
    max-width:90px;
  }
`

const Listy = styled.ol`
  counter-reset: numList;
`

const ImageWrapper = styled.div`
  height:100%;
  @media screen and (max-width:768px) {
    max-height: calc(300px + 10vw);
    overflow: hidden;
  }
`

const ListEl = styled.li`
  position:relative;
  line-height:1.4;

  >span {
    padding-left:2rem;
  }
  &:before {
    counter-increment: numList;
    content: counter(numList);


    position: absolute;
    left: 0;
    top:2px;


    font-size:12px;
    font-weight:600;
    text-align: center;
    color: #fff;
    line-height: 18px;

    width: 20px;
    height: 20px;
    background: #000;

    -moz-border-radius: 999px;
    border-radius: 999px
  }
`

export const query = graphql`
  query page($id: String!){
    wordpressPage(id: {eq: $id}) {
      title
      ...Yoast
      acf {
        hero {
          ... Hero
        }
        process {
          nav_heading
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:960) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        expertise {
          heading
          content
          devices {
            heading
            content
            logos
            we_repair {
              item
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth:952) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        two_column_sections {
          heading
          content
          subheading
          subheading_content
          logos
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:589) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`



const HowItWorksPage = ({ data: { wordpressPage: { acf, yoast_meta }} }) => {
  const links = [
    {
      title: 'Devices we repair',
      link: 'devices'
    },
    {
      title: 'Extended Warranty Claims',
      link: 'warranty-claims'
    },
    {
      title: 'Home & Travel Insurance Claims',
      link: 'home-insurance-claims'
    }
  ];

  const { hero, expertise, process } = acf;
  const [extend, home] = acf.two_column_sections;

  return (
    <Layout>
      <SEO yoast={yoast_meta} />

      <Hero
        heading={hero.hero.heading}
        content={hero.hero.content}
        image={hero.hero.image.localFile.childImageSharp.fluid}
        buttons={hero.hero.buttons}
        reverse={true}
        bg={hero.hero.background_colour || 'green'}
      />

      <section className="mt-4 md:mt-10 py-4 md:py-10">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-3 gap-3">
            {links.map(button =>
              <div className="lg:p-5 p-3 bg-tertiary text-center" key={button.title}>
                <a href={`#${button.link}`} className="c-btn--underline">{button.title}</a>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="md:pt-12 pt-8 md:mt-4" id="process">
        <Steps steps={process} />
      </div>

      <section className="my-16 sm:pt-12 pt-2 expert" id="devices">
        <div className="o-wrapper">
          <header className="grid md:grid-cols-2 md:gap-10 gap-6 md:mb-6">
            <h2 className="u-h2 md:max-w-xs">{expertise.heading}</h2>
            <p>{expertise.content}</p>
          </header>

          <div className="flex xl:-ml-24 -ml-20 items-start">
            <aside className="hidden md:block xl:pl-24 pl-20 pt-12 sticky top-header-height" style={{flex: '1 1 auto'}}>
              <nav>
                <Scrollspy items={expertise.devices.map(s => slugify(s.heading))}  currentClassName="is-current">
                  {expertise.devices.map(item =>
                    <NavItem key={`sticknav-${item.heading}`}>
                      <a href={`#${slugify(item.heading)}`} className="inline-block mb-3">
                        {item.heading}
                      </a>
                    </NavItem>
                  )}
                </Scrollspy>
              </nav>
            </aside>

            <main className="flex-auto xl:pl-24 pl-20">
              {expertise.devices.map((item, cnt) =>
                <section
                  id={slugify(item.heading)}
                  className={`${cnt < expertise.devices.length -1 ? 'mb-8 sm:mb-16 md:mb-20' : ''} pt-12`}
                  style={{maxWidth:'952px'}}
                  key={`sticky-section-${item.heading}`}
                >
                  <AspectRatio ratio="952/516" className="bg-tertiary breakout-wrapper-until-md">
                    <div className="absolute inset-0 img--cover">
                      <Image fluid={item.image.localFile.childImageSharp.fluid} draggable={false} />
                    </div>
                  </AspectRatio>

                  <div className="grid lg:grid-cols-2 xl:gap-20 lg:gap-12 gap-8 md:mt-12 sm:mt-10 mt-8">
                    <div>
                      <h3 className="u-h3 mb-5">{item.heading}</h3>
                      <p dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                    <div>
                      <h5 className="u-h5 mb-6">We repair</h5>
                      <Listy className="grid text-sm sm:grid-cols-2 gap-4">
                        {item.we_repair.map((rs, i) =>
                          <ListEl key={item.heading+rs.item+i} className="flex items-start justify-start">
                            <span>{rs.item}</span>
                          </ListEl>
                        )}
                      </Listy>
                    </div>
                  </div>

                  <div className="mt-6 flex flex-wrap -ml-8 items-center">
                    {item.logos.map(logo =>
                      <Logo key={logo+item.heading} className="mt-4 pl-8">
                        <img src={logo} alt=""/>
                      </Logo>
                    )}
                  </div>

                </section>
              )}
            </main>
          </div>
        </div>
      </section>


      <section className="lg:mt-28 md:mt-24 mt-16 xl:py-24 sm:py-20 py-16 bg-tertiary" id="warranty-claims">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-2 gap-10 items-center">
            <ImageWrapper>
              <Image className="h-full" fluid={extend.image.localFile.childImageSharp.fluid} draggable={false} />
            </ImageWrapper>

            <div className="flex flex-col justify-center">
              <div className="md:max-w-md mx-auto md:py-16">
                <h2 className="md:u-h2 u-h3 mb-6">{extend.heading}</h2>
                <p>{extend.content}</p>
                <div className="border-b border-grey mt-10 mb-6"></div>
                <h4 className="u-h5 mb-3">{extend.subheading}</h4>
                <p className="font-light">{extend.subheading_content}</p>

                <div className="mt-8 logos max-w-sm" style={{height:'33px'}}>
                  <div className="flex flex-wrap justify-between items-center h-full">
                    {extend.logos.map(l =>
                      <img className="max-h-full transform scale-75 sm:scale-100" key={`extend`+l} src={l} alt="" />
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:py-24 sm:py-20 py-16" id="home-insurance-claims">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-2 gap-10 items-center">
            <div className="flex flex-col justify-center">
              <div className="md:max-w-md mx-auto md:py-16">
                <h2 className="md:u-h2 u-h3 mb-6">{home.heading}</h2>
                <p>{home.content}</p>
                <div className="border-b border-grey mt-10 mb-6"></div>
                <h4 className="u-h5 mb-3">{home.subheading}</h4>
                <p className="font-light">{home.subheading_content}</p>

                <div className="mt-8 logos max-w-sm" style={{height:'42px'}}>
                  <div className="flex flex-wrap justify-between items-center h-full">
                    {home.logos.map(l =>
                      <img className="max-h-full transform scale-75 sm:scale-100" key={`home`+l} src={l} alt="" />
                    )}
                  </div>
                </div>

              </div>
            </div>

            <ImageWrapper>
              <Image className="h-full" fluid={home.image.localFile.childImageSharp.fluid} draggable={false} />
            </ImageWrapper>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default HowItWorksPage
