import React, { Component } from 'react'
import styled from 'styled-components'
import AspectRatio from 'react-aspect-ratio'
import Image from 'gatsby-image';
import Anime from 'react-anime';

const Progress = styled.ul`
  counter-reset: step;
  display:flex;
  max-width:1220px;
  margin:auto;

  li {
    list-style-type: none;
    width: 25%;
    font-size: 14px;
    line-height: 1.3;
    position: relative;
    text-align: center;
    color: #8e909b;
    vertical-align:middle;
    user-select:none;
    &,
    &:before,
    &:after {
      transition:all .5s ease-out;
    }

    @media screen and (min-width:640px) {
      font-size:18px;
    }

    &:before {
      font-weight:500;
      content: counter(step);
      counter-increment: step;
      border: 2px solid #CFD5E1;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
      cursor:pointer;


      width: 40px;
      height: 40px;
      font-size:16px;
      line-height: 2.2;
      @media screen and (min-width:640px) {
        width: 56px;
        height: 56px;
        font-size:18px;
        line-height: 2.8;
      }
    }
/*
    &:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #CFD5E1;

      left: -50%;
      z-index: -1;
      cursor:default;
      top: 20px;
      @media screen and (min-width:640px) {
        top: 27px;
      }

    } */

    &:first-child:after {
      content: none;
    }

    &.active {
      font-weight:600;
      color:#000000;
    }

    &.active:before {
      border-color: #00B7A8;
      font-weight:600;
      color:#00B7A8;
    }
/*
    &.active:after {
      background-color: #00B7A8;
    } */
  }
`

const StepLine = styled.div`
  width: 100%;
  height: 2px;
  margin:auto;
  position: absolute;

  z-index: -1;
  cursor:default;
  top: 20px;
  left: 50%;

  > div {
    height:100%;
    width:100%;
    background-color: #CFD5E1;

    position:relative;
    &:after {
      content:'';
      position:absolute;
      top:0;left:0;right:0;bottom:0;
      height:100%;width:100%;
      transform:scaleX(0);
      transform-origin:left;
      background-color:#00B7A8;
      transition:transform .5s ease-out;
    }
  }

  .active-before & {
    > div:after {
      transform:none;
    }
  }

  @media screen and (min-width:640px) {
    top: 27px;
  }
`


export default class Steps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 1
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(i) {
    if (this.state.active === i+1) return;
    this.setState({...this.state, active: i+1 });
  }

  render() {
    return (
      <div className="steps">
        <Progress>
          {this.props.steps.map((item, i) =>
            // eslint-disable-next-line
            <li
              key={`navstep${i}`}
              className={`${this.state.active <= i ? '': 'active'} ${this.state.active <= i+1 ? '': 'active-before'}`}
              onClick={()=> this.handleChange(i)}
            >
              {i < 3 && <StepLine><div /></StepLine>}
              <div className="cursor-pointer">
                {item.nav_heading || item.heading}
              </div>
            </li>
          )}
        </Progress>

        {/* Content */}
        <section className="sm:mt-12 mt-8">
          <div className="o-wrapper" style={{maxWidth:'1329px'}}>
            {this.props.steps.map((item, i) =>
              <Content
                key={`progress-content-${i}`}
                heading={item.heading}
                content={item.content}
                image={item.image}
                active={this.state.active}
                current={i+1}
              />
            )}
          </div>
        </section>
      </div>
    )
  }
}


const TheImage = styled.div`
  @media screen and (min-width: 640px) {
    max-width: 75%;
    padding-top:3rem;
  }
`

const TheText = styled.div`
  @media screen and (min-width: 640px) {
    max-width: 26rem;
  }
`


class Content extends Component {
  render() {
    const colours = ['tan',  'blue-light', 'tan-light', 'green-light'];

    return (
      <>
        {this.props.active === this.props.current &&

          <div className={`relative flex flex-col sm:pb-20`}>
            <TheImage>
              <AspectRatio ratio="810/591" className="sm:block bg-tertiary rounded-sm hero-ratio-image">
                {this.props.image && this.props.image.localFile &&
                  <Anime
                    translateY={[(this.props.current % 2 === 0 ? -20 : 20 ), 0]}
                    opacity={[0, 1]}
                    duration={900}
                  >
                    <Image fluid={this.props.image.localFile.childImageSharp.fluid} className="object-cover h-100 w-100" draggable={false} />
                  </Anime>
                }
              </AspectRatio>
            </TheImage>

            <div className="hidden sm:flex absolute top-0 right-0 w-full h-full flex-col justify-start">
              <AspectRatio ratio="949/654" className="mr-0 ml-auto w-full h-full rounded-sm" style={{maxHeight:'72%', maxWidth:'700px', zIndex:'-1'}}>
                <Anime
                  translateX={[-5, 0]}
                  translateY={[-5, 0]}
                  opacity={[0, 1]}
                  duration={900}
                  delay={80}
                >
                  <div className={`bg-${colours[this.props.current - 1]} h-full w-full`}></div>
                </Anime>
              </AspectRatio>
            </div>

            <div className="sm:absolute top-0 right-0 sm:mb-8 mt-8 sm:mt-24">
              <Anime
                translateY={[5, 0]}
                translateX={[5, 0]}
                opacity={[0, 1]}
                duration={900}
                delay={120}
              >
                <div className="sm:shadow-lg lg:shadow-none sm:py-12 md:px-10 sm:px-6 bg-white inline-block md:mx-12 sm:mx-6 sm:mb-12 rounded-sm">
                  <TheText className="text-left">
                    <h3 className="sm:u-h2 u-h3 mb-6">{this.props.heading}</h3>
                    <p dangerouslySetInnerHTML={{__html:this.props.content}} />
                  </TheText>
                </div>
              </Anime>
            </div>

          </div>
        }
      </>
    )
  }
}
